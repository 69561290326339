import { FC, useEffect, useMemo, useState } from "react"

import { nanoid } from "nanoid"
import { Cell, Column } from "react-table"

import {
	BinLocation,
	InventoryRequest,
	makeApiErrorMessage,
	useCreateInventoryRequestLine,
	useUpdateInventoryRequestLine,
} from "@ncs/ncs-api"
import { displayNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ExtendableModalProps,
	Heading,
	IconButton,
	LocationBinSelector,
	Modal,
	NumericInput,
	Table,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface InventoryPartDetailsModalProps extends ExtendableModalProps {
	requestId: string
	locationId: string
	part: InventoryRequest["parts"][number]
	canEdit: boolean
}

type PartBin = {
	tempId: string
	lineId: string | null
	currentCount: number | null
	newCount: number | null
	binCode: string | null
	selectedBin: BinLocation | null
}

interface BinState {
	[tempId: string]: PartBin
}

export const InventoryPartDetailsModal: FC<InventoryPartDetailsModalProps> = ({
	requestId,
	locationId,
	part,
	canEdit,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [bins, setBins] = useState<BinState>(() => {
		return Object.fromEntries(
			part.binInfo.map((bin) => {
				const tempId = nanoid()

				return [
					tempId,
					{
						tempId,
						lineId: bin.id,
						currentCount: bin.currentQuantity,
						newCount: bin.countedQuantity,
						binCode: bin.binCode,
						selectedBin: null,
					},
				]
			})
		)
	})

	const createLine = useCreateInventoryRequestLine(requestId)
	const updateLine = useUpdateInventoryRequestLine(requestId)

	const addBin = () => {
		const tempId = nanoid()

		setBins((prev) => ({
			...prev,
			[tempId]: {
				tempId,
				lineId: null,
				currentCount: null,
				newCount: null,
				binCode: null,
				selectedBin: null,
			},
		}))
	}

	const deleteBin = (binTempId: string) => {
		setBins((prev) => {
			delete prev[binTempId]
			return { ...prev }
		})
	}

	const handleSave = async () => {
		try {
			// Check that the same bin code isn't being used more than once.
			const codes: string[] = []
			Object.values(bins).forEach((bin) => {
				const code = bin.selectedBin?.code || bin.binCode
				if (code) {
					if (codes.includes(code)) {
						throw new Error(`Bin code ${code} is being used more than once`)
					} else {
						codes.push(code)
					}
				}
			})

			// Go through our bin lines and decide if they're unchanged, patch, or post.
			const patches: Parameters<typeof updateLine>[0][] = []
			const posts: Parameters<typeof createLine>[0][] = []

			Object.values(bins).forEach((bin) => {
				// For an existing bin row, find the original and see if the amount has changed.
				if (bin.lineId) {
					const original = part.binInfo.find((b) => b.binCode === bin.binCode)

					if (original?.countedQuantity !== bin.newCount) {
						patches.push({
							id: bin.lineId,
							updates: {
								quantity: bin.newCount || 0,
							},
						})
					}
				} else {
					// Otherwise, if we have what we need go ahead and create it.
					if (bin.selectedBin && bin.newCount) {
						posts.push({
							partId: part.partId,
							requestId,
							quantity: bin.newCount,
							binId: bin.selectedBin.id,
						})
					}
				}
			})
			if (posts.length || patches.length) {
				setSaving()
				await Promise.all([
					...patches.map((p) => updateLine(p)),
					...posts.map((p) => createLine(p)),
				])
				makeSuccessToast("Request counts updated")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const columns = useMemo((): Column<PartBin>[] => {
		return [
			{
				Header: "Bin code",
				accessor: ({ selectedBin, binCode, lineId, tempId }) => {
					if (lineId) {
						return binCode
					} else {
						return (
							<Box d="flex" left={-0.5} width={15}>
								<IconButton
									icon="times"
									color="gray"
									onClick={() => deleteBin(tempId)}
								/>
								<LocationBinSelector
									locationId={locationId}
									fillContainer
									value={selectedBin}
									placeholder="Bin..."
									label={null}
									mb={0}
									onChange={(newBin) => {
										setBins((prev) => ({
											...prev,
											[tempId]: {
												...prev[tempId],
												binCode: newBin?.code ?? null,
												selectedBin: newBin,
											},
										}))
									}}
								/>
							</Box>
						)
					}
				},
			},
			{
				Header: "Original qty",
				accessor: ({ currentCount }) => displayNumber(currentCount),
			},
			{
				Header: "Inventory count qty",
				Cell: ({ row: { original } }: Cell<PartBin>) => {
					if (canEdit) {
						return (
							<NumericInput
								value={original.newCount}
								onChange={(newValue) => {
									setBins((prev) => ({
										...prev,
										[original.tempId]: {
											...prev[original.tempId],
											newCount: newValue ?? null,
										},
									}))
								}}
								placeholder="Count..."
								mb={0}
								maxWidth={5}
								allowNegative
							/>
						)
					}

					return displayNumber(original.newCount)
				},
			},
		]
	}, [locationId, canEdit])

	const isDirty = useMemo(() => {
		if (Object.values(bins).some((newBin) => !newBin.lineId)) {
			return true
		}

		return part.binInfo.some((originalBin) => {
			const match = Object.values(bins).find((formBin) => {
				return formBin.binCode === originalBin.binCode
			})

			return match
		})
	}, [bins, part.binInfo])

	useEffect(() => {
		setErrorText(null)
	}, [bins])

	return (
		<Modal
			{...rest}
			title="Part Bin Quantities"
			errorText={errorText}
			rightButtons={
				canEdit ?
					{
						buttonText: "Save Counts",
						isLoading: isSaving(),
						onClick: handleSave,
						disabled: !isDirty,
					}
				:	undefined
			}
		>
			<Heading mt={1} mb={2}>
				({part.partNumber}) {part.partDescription}
			</Heading>

			{canEdit && (
				<Button icon="plus" onClick={addBin} containerProps={{ mb: 0.5 }}>
					Add stock from another bin
				</Button>
			)}

			<Table
				data={Object.values(bins)}
				columns={columns}
				rowVerticalAlign="middle"
				disableAllSorting
			/>
		</Modal>
	)
}
